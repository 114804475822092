import { environment } from '@env/environment';
import { BillingType, PaymentType, StatusType, OrderType } from '../types';
import { OPTION } from '../enums';

import {
    OrderDto,
    OrderCustomerDto,
    OrderCustomerAddressDto,
    OrderPassengerDto,
    OrderPaymentDto,
    OrderPriceDto,
    OrderRouteDto,
    OrderRouteGeopositionDto,
    OrderRouteTransitDto,
    OrderTransactionDto,
    OrderTransactionItemDto,
    OrderBookingDto,
} from './order.dto';

export class Order {
    id: string;
    type: OrderType;
    customer: OrderCustomer;
    passengers: OrderPassenger[];
    route: OrderRoute;
    returnRoute: OrderRoute | null;
    bookings: OrderBooking[];
    transactions: OrderTransaction[];
    payments: OrderPayment[];
    comment: string;
    status: StatusType;
    number: string;
    discountCode: string | null;

    constructor(data: OrderDto) {
        this.id = data.id;
        this.type = data.bookings.length === 0 ? 'parcel' : 'passenger';
        this.customer = new OrderCustomer(data.customer);
        this.passengers = data.passengers.map((item) => new OrderPassenger(item));
        this.route = new OrderRoute(data.route);
        this.returnRoute = data.returnRoute ? new OrderRoute(data.returnRoute) : null;
        this.bookings = data.bookings.map((item) => new OrderBooking(item));
        this.transactions = data.transactions.map((item) => new OrderTransaction(item, data.bookings));
        this.payments = data.payments.map((item) => new OrderPayment(item));
        this.comment = data.comments ?? '';
        this.status = data.status;

        const paymentId = this.transactions[0].paymentId ?? null;
        if (paymentId !== null && this.payments.find((payment) => payment.id === paymentId)?.status === 'succeeded') {
            this.status = 'paid';
        }

        this.number = data.number;
        this.discountCode = data.discountCode;
    }
}

export class OrderCustomer {
    companyName: string;
    nip: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: OrderPhone;
    address: OrderCustomerAddress;
    type: BillingType;

    constructor(data: OrderCustomerDto) {
        this.companyName = data.companyName ?? '';
        this.nip = data.nip ?? '';
        this.firstName = data.firstName ?? '';
        this.lastName = data.lastName ?? '';
        this.email = data.email ?? '';
        this.phone = new OrderPhone(data.phone);
        this.address = new OrderCustomerAddress(data.address);
        this.type = data.type ?? 'person';
    }
}

export class OrderCustomerAddress {
    country: string;
    city: string;
    voivodeship: string;
    street: string;
    houseNumber: string;
    flatNumber: string;
    postalCode: string;

    constructor(data: OrderCustomerAddressDto) {
        this.country = data.country ?? '';
        this.city = data.city ?? '';
        this.voivodeship = data.voivodeship ?? '';
        this.street = data.street ?? '';
        this.houseNumber = data.houseNumber ?? '';
        this.flatNumber = data.flatNumber ?? '';
        this.postalCode = data.postalCode ?? '';
    }
}

export class OrderPassenger {
    id: string;
    firstName: string;
    lastName: string;
    phone: OrderPhone;

    constructor(data: OrderPassengerDto) {
        this.id = data.id;
        this.firstName = data.firstName ?? '';
        this.lastName = data.lastName ?? '';
        this.phone = new OrderPhone(data.phone);
    }
}

export class OrderRoute {
    id: string;
    panelId: string;
    departureDate: string;
    beforeNoon: boolean;
    from: OrderRouteTransit;
    to: OrderRouteTransit;
    price: OrderPriceDto;

    constructor(data: OrderRouteDto) {
        this.id = data.id;
        this.panelId = data.panelId;
        this.departureDate = data.departureDate;
        this.beforeNoon = data.beforeNoon;
        this.from = new OrderRouteTransit(data.from);
        this.to = new OrderRouteTransit(data.to);
        this.price = data.price;
    }
}
export class OrderRouteTransit {
    id: string;
    panelId: string;
    isPoint: boolean;
    date: string | null;
    hourFrom: string;
    hourTo: string;
    country: string;
    city: string;
    voivodeship: string;
    street: string;
    houseNumber: string;
    flatNumber: string | null;
    postalCode: string;
    locationId: string;
    accuracy: string;
    geoPosition: OrderRouteGeoposition;
    constructor(data: OrderRouteTransitDto) {
        this.id = data.id;
        this.panelId = data.panelId ?? '';
        this.isPoint = data.isPoint;
        this.date = data.date;
        this.hourFrom = data.hourFrom ?? '00:00';
        this.hourTo = data.hourTo ?? '23:59';
        this.country = data.country;
        this.city = data.city;
        this.voivodeship = data.voivodeship ?? '';
        this.street = data.street;
        this.houseNumber = data.houseNumber;
        this.flatNumber = data.flatNumber;
        this.postalCode = data.postalCode;
        this.locationId = data.locationId ?? '';
        this.accuracy = data.accuracy;
        this.geoPosition = new OrderRouteGeoposition(data.geoPosition);
    }
}

export class OrderRouteGeoposition {
    longitude: number;
    latitude: number;
    constructor(data: OrderRouteGeopositionDto) {
        this.longitude = data.longitude;
        this.latitude = data.latitude;
    }
}

export class OrderBooking {
    id: string;
    passengerId: string;
    routeId: string;
    constructor(data: OrderBookingDto) {
        this.id = data.id;
        this.passengerId = data.passengerId;
        this.routeId = data.routeId;
    }
}

export class OrderTransaction {
    id: string;
    paymentId: string | null;
    currency: string | null;
    items: OrderTransactionItem[];
    constructor(data: OrderTransactionDto, bookings: OrderBooking[]) {
        this.id = data.id;
        this.paymentId = data.paymentId ? data.paymentId : null;
        this.currency = data.currency ? data.currency : null;
        this.items = data.items.map((item) => new OrderTransactionItem(item, bookings));
    }
}

export class OrderTransactionItem {
    id: string | null;
    bookingId: string;
    routeId: string | null;
    name: string;
    price: OrderPriceDto;
    hidden: boolean;
    type: OPTION;

    constructor(item: OrderTransactionItemDto, bookings: OrderBooking[]) {
        this.id = item.id ? item.id : null;
        this.bookingId = item.booking;
        this.routeId = bookings.find((booking) => booking.id === item.booking)?.routeId ?? null;
        this.name = item.name;
        this.price = item.price;
        this.type = <OPTION>item.type.toString();
        this.hidden = item.type.toString() === OPTION.DISCOUNT;
    }
}

export class OrderPayment {
    amount: number;
    currency: string;
    id: string;
    method: string;
    provider: string;
    redirectUrl: string;
    status: PaymentType;
    constructor(data: OrderPaymentDto) {
        this.amount = data.amount;
        this.currency = data.currency;
        this.id = data.id;
        this.method = data.method;
        this.provider = data.provider;
        this.redirectUrl = data.redirectUrl;
        this.status = <PaymentType>data.status.toLocaleLowerCase();
    }
}

export class OrderPhone {
    prefix: string;
    number: string;
    constructor(phone: string | null) {
        this.prefix = environment.forms.phone.prefixes.default;

        const prefixes = environment.forms.phone.prefixes.items.map((item) => item.code);

        prefixes.forEach((prefix) => {
            if (phone?.startsWith(prefix)) {
                this.prefix = prefix;
            }
        });

        this.number = phone?.slice(this.prefix.length).split(' ').join('') ?? '';
    }
}
