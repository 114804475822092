import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, catchError, finalize, map, mergeMap, tap, filter, switchMap, take, debounceTime, distinctUntilChanged, forkJoin } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/operators';
import { GtmTrackingService } from '@app/shared';
import { AlertsActionsService, AlertsService } from '@app/shared/alerts';
import { LoaderFacade } from '@app/store/loader';
import { HttpService } from '../services/http.service';
import { orderActions } from '../actions/order.actions';
import { discountCodeActions } from '@app/store/discount-code/actions/discount-code.actions';
import { selectOrder, selectUpdatesPending, selectUpdating } from '../selectors/order.selector';
import { Order, OrderUpdateValue } from '../models';

@Injectable()
export class OrderEffects {
    private readonly actions = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loaderFacade = inject(LoaderFacade);
    // FIXME?
    readonly document = inject(DOCUMENT);
    readonly store = inject(Store);
    readonly alertsService = inject(AlertsService);
    readonly alertsActionsService = inject(AlertsActionsService);
    readonly gtmTracking = inject(GtmTrackingService);
    readonly router = inject(Router);

    createOrder$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.createOrder),
            mergeMap((action) => {
                this.loaderFacade.add('order-create-draft');
                return this.httpService.createOrder(action.value, action.transitFrom, action.transitTo).pipe(
                    map((response) => orderActions.createOrderSuccess({ payload: response })),
                    catchError((response: HttpErrorResponse) => of(orderActions.createOrderError({ response }))),
                    finalize(() => this.loaderFacade.remove('order-create-draft')),
                );
            }),
        );
    });

    createOrderError$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(orderActions.createOrderError),
                tap((response) => {
                    const msg = response.response.error as { type: string };
                    switch (response.response.status) {
                        case 410:
                            switch (msg?.type) {
                                case 'TimeLimitReachedException':
                                    this.alertsService.show('alert.error.booking-order-excceeded', 'warning', true);
                                    break;
                                case 'PassengerCountLimitReachedException':
                                    this.alertsService.show('alert.error.booking-order-limit-excceeded', 'warning', true);
                                    break;
                                default:
                                    this.alertsService.show('alert.error.generic-with-contact', 'danger', true);
                                    break;
                            }
                            break;
                        default: {
                            this.alertsService.show('alert.error.generic-with-contact', 'danger', true);
                            break;
                        }
                    }
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    getOrder$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.getOrder, discountCodeActions.attachSuccess, discountCodeActions.detachSuccess),
            mergeMap((action) => {
                this.loaderFacade.add('order-get');
                return this.httpService.getOrder(action.id).pipe(
                    map((payload) => orderActions.getOrderSuccess({ payload })),
                    catchError((error: HttpErrorResponse) => of(orderActions.getOrderError({ error }))),
                    finalize(() => this.loaderFacade.remove('order-get')),
                );
            }),
        );
    });

    getOrderByToken$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.getOrderByToken),
            mergeMap((action) => {
                this.loaderFacade.add('order-get');
                return this.httpService.getOrderByToken(action.id, action.token).pipe(
                    map((payload) => orderActions.getOrderSuccess({ payload })),
                    catchError((response: HttpErrorResponse) => of(orderActions.getOrderByTokenError({ response }))),
                    finalize(() => this.loaderFacade.remove('order-get')),
                );
            }),
        );
    });

    // getBookingExternalError$ = createEffect(
    //     () => {
    //         return this.actions.pipe(
    //             ofType(bookingActions.getBookingByTokenErrorAction),
    //             tap((response) => {
    //                 this.loaderFacade.remove('booking-get');
    //                 switch (response.response.status) {
    //                     case 404:
    //                         this.alertsService.show('alert.error.generic.not-found', 'danger', true);
    //                         break;
    //                     case 502:
    //                         this.alertsService.show('alert.error.booking.not-found-or-paid', 'danger', true);
    //                         break;
    //                     default: {
    //                         this.alertsService.show('alert.error.generic', 'danger', true);
    //                         break;
    //                     }
    //                 }
    //                 void this.router.navigate(['/']);
    //             }),
    //             map((response) => response.response.status),
    //         );
    //     },
    //     {
    //         dispatch: false,
    //     },
    // );

    // createBooking$ = createEffect(() => {
    //     return this.actions.pipe(
    //         ofType(bookingActions.createBookingAction),
    //         mergeMap((action) => {
    //             this.loaderFacade.add('booking-create-draft');
    //             return this.httpService.createBookingDraft(action.value, action.transitFrom, action.transitTo).pipe(
    //                 map((response) => bookingActions.createBookingSuccessAction({ payload: response })),
    //                 catchError((response: HttpErrorResponse) => of(bookingActions.createBookingErrorAction({ response }))),
    //                 finalize(() => this.loaderFacade.remove('booking-create-draft')),
    //             );
    //         }),
    //     );
    // });

    // createBookingError$ = createEffect(
    //     () => {
    //         return this.actions.pipe(
    //             ofType(bookingActions.createBookingErrorAction),
    //             tap((response) => {
    //                 const msg = response.response.error as { type: string };
    //                 switch (response.response.status) {
    //                     case 410:
    //                         switch (msg?.type) {
    //                             case 'TimeLimitReachedException':
    //                                 this.alertsService.show('alert.error.booking-order-excceeded', 'warning', true);
    //                                 break;
    //                             case 'PassengerCountLimitReachedException':
    //                                 this.alertsService.show('alert.error.booking-order-limit-excceeded', 'warning', true);
    //                                 break;
    //                             default:
    //                                 this.alertsService.show('alert.error.generic-with-contact', 'danger', true);
    //                                 break;
    //                         }
    //                         break;
    //                     default: {
    //                         this.alertsService.show('alert.error.generic-with-contact', 'danger', true);
    //                         break;
    //                     }
    //                 }
    //             }),
    //         );
    //     },
    //     {
    //         dispatch: false,
    //     },
    // );

    updateOrder$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.updateOrder),
            map(() => orderActions.updateOrderCheck()),
        );
    });

    updateOrderCheck$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.updateOrderCheck, orderActions.updateOrderSuccess, orderActions.updateOrderError),
            concatLatestFrom(() => [this.store.select(selectUpdating), this.store.select(selectUpdatesPending)]),
            filter(([, updating, value]) => updating === false && value !== null),
            map(() => orderActions.updateOrderRun()),
        );
    });

    updateOrderRun$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.updateOrderRun),
            concatLatestFrom(() => [this.store.select(selectOrder), this.store.select(selectUpdatesPending)]),
            mergeMap((data) => {
                const order = <Order>data[1];
                const value = <OrderUpdateValue>data[2];

                return this.httpService.updateOrder(order.id, value, order).pipe(
                    map((payload) => orderActions.updateOrderSuccess({ payload })),
                    catchError(() => of(orderActions.updateOrderError())),
                );
            }),
        );
    });

    updateOrderClear$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.updateOrderRun),
            map(() => orderActions.updateOrderClear()),
        );
    });

    // updateOrderRun$ = createEffect(() => {
    //     return this.actions.pipe(
    //         ofType(orderActions.updateOrderRun),
    //         exhaustMapWithTrailing((action) =>
    //             of(action).pipe(
    //                 concatLatestFrom(() => this.store.select(selectOrder)),
    //                 filter(([, order]) => order !== null),
    //                 delay(3000),
    //                 mergeMap(([action, order]) =>
    //                     this.httpService.updateOrder(action.id, action.value, <Order>order).pipe(
    //                         map((payload) => orderActions.updateOrderSuccess({ payload })),
    //                         catchError(() => of(orderActions.updateOrderError())),
    //                     ),
    //                 ),
    //             ),
    //         ),
    //     );
    // });

    // merge(,
    // this.actions.pipe(ofType(orderActions.submitOrderSuccess, orderActions.submitOrderError)).pipe(map(() => true)))

    submitOrder$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.submitOrder),
            switchMap((action) =>
                forkJoin([
                    of(action).pipe(take(1)),
                    this.store.select(selectUpdating).pipe(
                        debounceTime(10),
                        distinctUntilChanged(),
                        filter((value) => value === false),
                        take(1),
                    ),
                ]),
            ),
            mergeMap(([action]) => {
                if (action.transactionId !== null) {
                    return this.httpService.submitOrderTransaction(action.paymentId, action.transactionId).pipe(
                        map((payload) => orderActions.submitOrderSuccess({ payload })),
                        catchError(() => of(orderActions.submitOrderError())),
                    );
                }

                return this.httpService.submitOrder(action.orderId, action.paymentId).pipe(
                    map((payload) => orderActions.submitOrderSuccess({ payload })),
                    catchError(() => of(orderActions.submitOrderError())),
                );
            }),
        );
    });

    submitOrderRedirect$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(orderActions.submitOrderSuccess),
                tap((action) => (this.document.location.href = action.payload.redirectUrl)),
            );
        },
        { dispatch: false },
    );

    // resendActivatedEmail$ = createEffect(
    //     () => {
    //         return this.alertsActionsService.bus$.pipe(
    //             filter((event) => event.action === 'update-booking-reload-page'),
    //             tap(() => window.location.reload()),
    //         );
    //     },
    //     { dispatch: false },
    // );

    // submitBookingGTM$ = createEffect(
    //     () => {
    //         return this.actions.pipe(
    //             ofType(bookingActions.submitBookingSuccessAction),
    //             tap((action) => {
    //                 const price = action.payload.payments.map((payment) => payment.amount);
    //                 const currency = action.payload.payments.map((payment) => payment.currency.toUpperCase());
    //                 this.gtmTracking.beginCheckoutEvent(
    //                     price[0] / 100,
    //                     currency[0],
    //                     action.payload.transactions.map((transaction) => transaction.items),
    //                 );
    //             }),
    //         );
    //     },
    //     {
    //         dispatch: false,
    //     },
    // );

    // submitBookingError$ = createEffect(
    //     () => {
    //         return this.actions.pipe(
    //             ofType(bookingActions.submitBookingErrorAction),
    //             tap((response) => {
    //                 this.loaderFacade.remove('booking-submit');
    //                 const error = <ApiResponseError>response.response.error;
    //                 switch (response.response.status) {
    //                     case 410:
    //                         switch (error?.type) {
    //                             case 'TimeLimitReachedException':
    //                                 this.alertsService.show('alert.error.booking-order-excceeded', 'warning', true);
    //                                 break;
    //                             case 'PassengerCountLimitReachedException':
    //                                 this.alertsService.show('alert.error.booking-order-limit-excceeded', 'warning', true);
    //                                 break;
    //                             case 'PaymentExpiredException':
    //                                 this.alertsService.show('alert.error.paymnet-overdue', 'warning', true);
    //                                 break;
    //                             default:
    //                                 this.alertsService.show('alert.error.generic-with-contact', 'danger', true);
    //                                 break;
    //                         }
    //                         break;
    //                     case 400:
    //                         switch (error?.type) {
    //                             case 'OrderAlreadyConfirmedException':
    //                                 this.alertsService.show('booking.confirmation.status.paid', 'warning', true);
    //                                 break;
    //                             default:
    //                                 this.alertsService.show('alert.error.generic-with-contact', 'danger', true);
    //                                 break;
    //                         }
    //                         break;
    //                     case 422:
    //                         if (error && error.errors) {
    //                             error.errors?.forEach(({ title }) => this.alertsService.show(title, 'danger', false));
    //                         } else {
    //                             this.alertsService.show('alert.error.generic-with-contact', 'danger');
    //                         }
    //                         break;
    //                     default: {
    //                         this.alertsService.show('alert.error.generic-with-contact', 'danger', true);
    //                         break;
    //                     }
    //                 }
    //             }),
    //         );
    //     },
    //     {
    //         dispatch: false,
    //     },
    // );

    // submitBookingPayment$ = createEffect(() => {
    //     return this.actions.pipe(
    //         ofType(bookingActions.submitBookingPaymentAction),
    //         mergeMap((action) =>
    //             forkJoin([
    //                 of(action),
    //                 this.store.select(selectBooking).pipe(
    //                     filter((data): data is BookingModel => data !== null),
    //                     take(1),
    //                 ),
    //             ]),
    //         ),
    //         concatMap(([action, booking]) => {
    //             this.loaderFacade.add('booking-submit-payment');
    //             return this.httpService.submitBookingPayment(action.id, action.value, booking).pipe(
    //                 map((data) =>
    //                     bookingActions.submitBookingPaymentSuccessAction({
    //                         payload: data,
    //                     }),
    //                 ),
    //                 catchError((error: HttpErrorResponse) => of(bookingActions.submitBookingPaymentErrorAction({ error }))),
    //             );
    //         }),
    //     );
    // });

    // submitBookingByTokenPayment$ = createEffect(() => {
    //     return this.actions.pipe(
    //         ofType(bookingActions.submitBookingByTokenPaymentAction),
    //         mergeMap((action) =>
    //             forkJoin([
    //                 of(action),
    //                 this.store.select(selectBooking).pipe(
    //                     filter((data): data is BookingModel => data !== null),
    //                     take(1),
    //                 ),
    //             ]),
    //         ),
    //         concatMap(([action, booking]) => {
    //             this.loaderFacade.add('booking-submit-payment');
    //             return this.httpService.submitBookingByTokenPayment(action.id, action.value, action.token, booking).pipe(
    //                 map((data) =>
    //                     bookingActions.submitBookingPaymentSuccessAction({
    //                         payload: data,
    //                     }),
    //                 ),
    //                 catchError((error: HttpErrorResponse) => of(bookingActions.submitBookingPaymentErrorAction({ error }))),
    //             );
    //         }),
    //     );
    // });

    // submitBookingPaymentSuccess$ = createEffect(
    //     () => {
    //         return this.actions.pipe(
    //             ofType(bookingActions.submitBookingPaymentSuccessAction),
    //             tap((action) => {
    //                 this.document.location.href = action.payload.redirectUrl;
    //                 this.loaderFacade.remove('booking-submit-payment');
    //             }),
    //         );
    //     },
    //     {
    //         dispatch: false,
    //     },
    // );

    // submitBookingPaymentError$ = createEffect(
    //     () => {
    //         return this.actions.pipe(
    //             ofType(bookingActions.submitBookingPaymentErrorAction),
    //             tap((error) => {
    //                 this.loaderFacade.remove('booking-submit-payment');
    //                 switch (error.error.status) {
    //                     case 410:
    //                         this.alertsService.show('alert.error.paymnet-overdue', 'warning', true);
    //                         break;
    //                     default: {
    //                         this.alertsService.show('alert.error.generic-with-contact', 'danger', true);
    //                         break;
    //                     }
    //                 }
    //             }),
    //         );
    //     },
    //     {
    //         dispatch: false,
    //     },
    // );
}
