import { OPTION } from '../enums';
import { BillingType } from '../types';
import { Order, OrderPassenger, OrderTransaction } from './order';
import { OrderUpdateAddressValue, OrderUpdateCustomerValue, OrderUpdateOptionValue, OrderUpdatePaymentValue, OrderUpdateValue } from './order-update.value';

export class OrderUpdateValueDto {
    invoice: boolean;
    comments: string | null;
    customer: OrderUpdateCustomerValueDto;
    passengers: OrderPassengerValueDto[];
    transactions: OrderUpdateTransactionValueDto[];

    constructor(value: OrderUpdateValue, order: Order) {
        this.invoice = value.customer.billing.type === 'company';
        this.comments = value.comment.trim() === '' ? null : value.comment.trim();
        this.customer = new OrderUpdateCustomerValueDto(value.customer);
        this.passengers = value.passengers.map((passenger) => new OrderPassengerValueDto(passenger));
        this.transactions = order.transactions.map((transaction) => new OrderUpdateTransactionValueDto(transaction, value.payment, value.options));
    }
}

export class OrderUpdateTransactionValueDto {
    id: string;
    currency: string;
    items: OrderUpdateTransactionItemValueDto[];
    constructor(transaction: OrderTransaction, payment: OrderUpdatePaymentValue, options: OrderUpdateOptionValue[]) {
        this.id = transaction.id;
        this.currency = payment.currency;
        this.items = new OrderUpdateTransactionItemsValueDto(transaction, options).items;
    }
}

export class OrderUpdateTransactionItemsValueDto {
    items: OrderUpdateTransactionItemValueDto[] = [];
    constructor(transaction: OrderTransaction, options: OrderUpdateOptionValue[]) {
        const prevItems = transaction.items.map((item) => ({
            id: item.id,
            bookingId: item.bookingId,
            type: item.type,
        }));

        options.forEach((option) => {
            const items = Object.values(option.routes)
                .flat()
                .filter((item) => item.value === true || (item.value !== false && item.value > 0));

            items.forEach((item) => {
                const index = prevItems.findIndex((prevItem) => prevItem.type === option.id && prevItem.bookingId === item.bookingId);
                const id = index !== -1 ? prevItems.splice(index, 1)[0].id : null;

                this.items.push({
                    id: id,
                    booking: item.bookingId,
                    type: option.id,
                });
            });
        });
    }
}

export interface OrderUpdateTransactionItemValueDto {
    id: string | null;
    booking: string;
    type: OPTION;
}

export class OrderPassengerValueDto {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;

    constructor(value: OrderPassenger) {
        this.id = value.id;
        this.firstName = value.firstName.trim();
        this.lastName = value.lastName.trim();
        this.phone = `${value.phone.prefix}${value.phone.number}`.trim();
    }
}

export class OrderUpdateCustomerValueDto {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    companyName: string | null = null;
    nip: string | null = null;
    type: BillingType;
    address: OrderUpdateAddressValueDto;

    constructor(value: OrderUpdateCustomerValue) {
        this.firstName = value.firstName.trim();
        this.lastName = value.lastName.trim();
        this.email = value.email.trim();
        this.phone = `${value.phone.prefix}${value.phone.number}`.trim();
        this.type = value.billing.type;

        if (value.billing.type === 'company') {
            this.companyName = value.billing.companyName;
            this.nip = value.billing.nip;
        }

        this.address = new OrderUpdateAddressValueDto(value.billing.address);
    }
}

export class OrderUpdateAddressValueDto {
    country: string | null;
    city: string;
    voivodeship = '';
    street: string;
    houseNumber = '';
    flatNumber = '';
    postalCode: string;

    constructor(value: OrderUpdateAddressValue) {
        this.country = value.country.trim() || null;
        this.city = value.city.trim();
        this.street = value.street.trim();
        this.postalCode = value.postalCode.trim();
    }
}
